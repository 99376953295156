import {ContainerAware} from "./dependency-injection/container.aware";

/**
 * @deprecated Для компонентов не стоит использовать абстракцию
 */
export class AbstractComponent extends ContainerAware {
    constructor() {
        super();
    }

    Api2Proxy() {
        if (!this.container.has('Api2Proxy')) {
            throw 'Api2Proxy not found';
        }
        return this.container.get('Api2Proxy');
    }

    Html() {
        return this.container.get('Html')
    }

    Notify() {
        return this.container.get('Notify')
    }
}