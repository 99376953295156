import $ from 'jquery';
import { AbstractComponent } from '../../core/abstract.component';

export default class SearchComponent extends AbstractComponent {
    constructor(view) {
        super();
        this.view = view;
        const $separateShowRemains = $('#separateShowRemains');
        this.isSeparateShowRemains = !!+$separateShowRemains.val();
    }

    handlerDiscountedItems(event) {
        const self = this;
        console.info(this);
        const $self = $(event.currentTarget).closest('div');
        $self.find('a').blur();
        const $tr = $self.closest('tr');
        const article = $tr.find('.aArticlePrimenyamost').text();
        const brand = $tr.find('.brand').text();
        const _downDiscountedItems = function () {
            $self.find('img:first').attr('src', '/design/img/arrow-down-small.png').end()
                .find('a')
                .text(i18next.t('показать уцененные товары'));
        };
        const _upDiscountedItems = function () {
            $self.find('img:first').attr('src', '/design/img/arrow-up-small.png').end()
                .find('a')
                .text(i18next.t('скрыть уцененные товары'));
        };
        const $resultsContent = self.view.currentPanel().find('.results-content');
        const articleWithBrand = `${article}_${brand}`;
        if (+$self.attr('data-status') === 1) {
            const src = $self.find('img:first').attr('src');
            const $children = $resultsContent.find(`tr.discounted[data-parent-article="${articleWithBrand}"]`);
            if (src === '/design/img/arrow-up-small.png') {
                _downDiscountedItems();
                $children.hide();
            } else if (src === '/design/img/arrow-down-small.png') {
                _upDiscountedItems();
                $children.show();
            }
            return;
        }
        $self.attr('data-status', 1)
            .find('img:first').attr('src', '/design/img/preloader-s.gif').end();
            //.find('a').text(i18next.t('Пожалуйста, подождите, данные загружаются'));
        const data = {
            method: 'getDiscountedItems',
            params: {
                article,
                brand,
            },
        };
        const stocks = $tr.find('.a').toArray().map((element) => +element.id);
        const currentPanelId = self.view.currentPanel().attr('id');
        self.Api2Proxy().send(data).then((data) => {
            if (data.code !== 0) {
                throw data.message;
            }
            const { items } = data;
            const trs = [];
            for (const column in items) {
                if (!items.hasOwnProperty(column)) {
                    continue;
                }
                let resultRow = self.view.resultRow(items[column], stocks);
                resultRow = $.parseHTML(resultRow).filter((value) => {
                    if (Node.ELEMENT_NODE !== value.nodeType) {
                        return false;
                    }
                    const $self = $(value);
                    if ($self.hasClass('item') && !$self.hasClass('item-fake')) {
                        $self.addClass('discounted hidden');
                    }
                    const $productInformation = $self.find('.product_information');
                    if ($productInformation.length) {
                        $productInformation.append(
                            `<div id="searchItemProperty"><b>` + i18next.t('Уцененный товар') +
                            `: <span class="hisearch">${article}</span></b></div>`,
                        );
                        if (currentPanelId === 'XLoad') {
                            const rowspan = self.isSeparateShowRemains ? 3 : 1;
                            $productInformation.after(
                                `<td style="text-align: center; width: 51px;" rowspan="${rowspan}">
    <span id="needXloaditem" style="font-size: 20px;">${$tr.find('#needXloaditem').text()}</span>
</td>`,
                            );
                        }
                    }
                    return true;
                });
                trs.push(...resultRow);
            }
            if (self.isSeparateShowRemains) {
                $tr.next('.item-packed').next('.item-transit').after(trs);
            } else {
                $tr.after(trs);
            }
            let $trsNotItemFakeHidden = $resultsContent
                .find(`tr.discounted[data-parent-article="${articleWithBrand}"]:not(.item-fake,:visible)`);
            const limit = 20;
            let $trs = $trsNotItemFakeHidden.slice(0, limit);
            let count = 0;
            const _receiptOfBalancesAndPrices = function () {
                $trs.find('#search-loader').closest('tr').remove();
                $trs = $trs.filter(function () {
                    return this.parentNode;
                });
                count += $trs.length;
                $trsNotItemFakeHidden = $resultsContent
                    .find(`tr.discounted[data-parent-article="${articleWithBrand}"]:not(.item-fake,:visible)`);
                $trs = $trsNotItemFakeHidden.slice(count, limit + count);
                if (!$trs.length || 1 <= count / limit && count % limit) {
                    $trs = $trsNotItemFakeHidden.slice(0, limit);
                    $(`tr.item.discounted.item-fake[data-parent-article="${articleWithBrand}"]`).remove();
                    if ($trs.length) {
                        if (1 <= count / limit) {
                            count = count % limit;
                        } else {
                            count = 0;
                        }
                        _upDiscountedItems();
                        $trs.removeClass('hidden');
                        if (count) {
                            const $lastTrs = $($trs.toArray().reverse()[0]);
                            const html =
`<tr class="item discounted item-fake text-center" data-parent-article="${articleWithBrand}">
    <td colspan="${$lastTrs.find('td').length}">
        <button class="btn btn-primary" type="button">` + i18next.t('Показать еще') + `</button>
    </td>
</tr>`;
                            if (self.isSeparateShowRemains) {
                                $lastTrs.next('.item-packed').next('.item-transit').after(html);
                            } else {
                                $lastTrs.after(html);
                            }
                        }
                    } else {
                        $self.removeClass('.discounted-items')
                            .find('img:first').attr('src', '/design/img/warning.png').end()
                            .find('span')
                            .text(' ' + i18next.t('уцененные товары не найдены'))
                            .css('font-size', '12px');
                    }
                } else if ($trs.length) {
                    PI.getAllPrice($trs, false, false, false, _receiptOfBalancesAndPrices);
                }
            };
            if ($trs.length) {
                $resultsContent
                    .on(
                        'click.more',
                        `tr.item.discounted.item-fake[data-parent-article="${articleWithBrand}"] button`,
                        function (event) {
                    const $self = $(event.currentTarget);
                    $self.prop('disabled', true);//.text(i18next.t('Пожалуйста, подождите, данные загружаются'));
                    $self.after('<img src="/design/img/ajax-loader2.gif" alt=""/>');
                    $trsNotItemFakeHidden = $resultsContent
                        .find(`tr.discounted[data-parent-article="${articleWithBrand}"]:not(.item-fake,:visible)`);
                    $trs = $trsNotItemFakeHidden.slice(count, limit + count);
                    PI.getAllPrice($trs, false, false, false, _receiptOfBalancesAndPrices);
                });
                PI.getAllPrice($trs, false, false, false, _receiptOfBalancesAndPrices);
            }
        }).catch((exception) => {
            $self.removeClass('.discounted-items')
                .find('img:first').attr('src', '/design/img/warning.png').end()
                .find('span')
                .text(' ' + i18next.t('уцененные товары не найдены'))
                .css('font-size', '12px');
        });
    }

    normalizeFileStaticUrl(value) { /*218539 и.12945*/
        if ('en' === i18next.language) {
            value = value.replace(window.env.STATIC_URL, window.env.STATIC_URL.replace('.autotrade.su', '.pavelauto.parts'));
        } else if (window.location.host.indexOf('.autotrade.kz') !== -1) {
            value = value.replace(window.env.STATIC_URL, window.env.STATIC_URL.replace('.autotrade.su', '.autotrade.kz'));
        }
        return value.replace(window.env.MINIO_BUCKET_NAME, window.env.MINIO_BUCKET_NAME_ALIAS);
    }
}
