export class ContainerAware {
    constructor() {
        this.container = {}
    }

    /**
     * @param {Container} container
     */
    setContainer(container) {
        this.container = container;
    }
}